import React, {createContext, useContext, useState, useEffect} from 'react';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import {doc, onSnapshot} from "firebase/firestore";
import {getFirestore} from "firebase/firestore";

const UserContext = createContext();
var userCompany = null;

export const useUser = () => {

  return useContext(UserContext);
};

export const setUserCompany = (company) => {
  userCompany = company;
};

export const UserProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [appUser, setAppUser] = useState(null);

  // set auth user observer on load
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, user => {
      setUser(user);
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let unsubscribe;
    if (user && userCompany) {
      const db = getFirestore();
      const docRef = doc(db, "companies", userCompany, "users", user.email);
      unsubscribe = onSnapshot(docRef, (snapshot) => {
        setAppUser({id: snapshot.id, ...snapshot.data()});
      });
    } else {
      setAppUser(null);
    }
    if (unsubscribe) {
      return unsubscribe;
    }
  }, [user, userCompany]);

  // context returns
  // user: firebase user
  // appUser: user object representing {account, role} with id of email
  const value = {
    user,
    appUser,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
