// Login.js
import React, {useEffect, useState} from 'react';
import {getFirestore, doc, getDoc} from "firebase/firestore";
import {getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink} from 'firebase/auth';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {useSnackbar} from "notistack";
import {getCollectionIds} from "../../utilities/firestore.js";
import '../../Styles.css';


function Login() {
  const db = getFirestore();
  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const [error, setError] = useState(null);
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, {variant: 'error'});
    }
  }, [error, enqueueSnackbar]);

  const [message, setMessage] = useState(null);
  useEffect(() => {
    if (message) {
      enqueueSnackbar(message, {variant: 'success'});
    }
  }, [message, enqueueSnackbar]);

  const auth = getAuth();

  const handleLogin = async () => {
    // make sure we have registered user with this email
    const db = getFirestore();
    const docRef = doc(db, "companies", company.label, "users", email);
    const myDoc = await getDoc(docRef);
    if (myDoc.exists()) {
      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true
      };

      try {
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        window.localStorage.setItem('emailForSignIn', email);
        setMessage("Link sent to your email. Please check your inbox.");
      } catch (err) {
        setError(err.message);
      }
    } else {
      setError("No user with this email found. Please contact the administrator or try again.");
    }
    setLoading(false);
  };

  // Check for email link on component mount
  useEffect(() => {
    const checkForEmailLink = async () => {
      setLoading(true);
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let storedEmail = window.localStorage.getItem('emailForSignIn');

        if (!storedEmail) {
          storedEmail = window.prompt('Please provide your email for confirmation');
        }

        try {
          await signInWithEmailLink(auth, storedEmail, window.location.href);
          window.localStorage.removeItem('emailForSignIn');
          setMessage("Successfully signed in!");
        } catch (err) {
          setError(err.message);
        }
      }
      setLoading(false);
    };
    checkForEmailLink();
  }, [auth]);

  const [companies, setCompanies] = useState({label: "", id: 0});
  useEffect(() => {
    getCollectionIds("companies").then((ids) => {
      if (ids.length === 0) return [{label: "No results ...", id: 0}]
      let c = [];
      ids.forEach((id, index) => {
        c.push({label: id, id: index});
      });
      setCompanies(c);
    });
  }, []);

  const [company, setCompany] = useState({});
  function handleCompanyChange(e, value) {
    if (value === null) {
      setCompany({label: "", id: 0});
      return;
    }
    setCompany({label: value.label, id: value.id});
  };


  return (
    <div className='login-view'>
      <img className='login-logo'
        src='lazy-192.png'
        alt='alaric-logo'
      />
      <h3>Sign in</h3>
      <p>Enter your company & email to start</p>
      <div className="login-form">
        <Autocomplete
          sx={{width: "80%"}}
          id="my-input"
          options={companies}
          value={company}
          onChange={handleCompanyChange}
          renderInput={(params) => (
            <TextField {...params} label="Select an option" variant="outlined" />
          )}
          getOptionLabel={(option) => option.label ? option.label : ""}
          isOptionEqualToValue={(option, value) => value.label === option.label || value === ""}
          openOnFocus
          limitTags={4}
        />
        <input
          className='login-text-input'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Email'
        />
        <button
          className='login-button'
          onClick={handleLogin}
          disabled={loading}>
          {loading ? "Loading..." : "Send me a login link"}
        </button>
      </div>
    </div>
  );
}

export default Login;
