import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {initializeApp} from "firebase/app";
import {getFirestore, doc, setDoc, serverTimestamp} from "firebase/firestore";
import {getMessaging, getToken} from "firebase/messaging";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from "firebase/auth";
import {SnackbarProvider} from "notistack";
import {useUser} from "./UserContext.js";
import TeamsAppBar from "./components/TeamsAppBar.js";
import Login from "./components/login/Login.js";
import './Styles.css';

const firebaseConfig = {
  apiKey: "AIzaSyA4dHSmjuxlEaFeciiMcs_ekK7e3yy6VuU",
  authDomain: "oktermsforme.firebaseapp.com",
  databaseURL: "https://oktermsforme.firebaseio.com",
  projectId: "oktermsforme",
  storageBucket: "oktermsforme.appspot.com",
  messagingSenderId: "851176471532",
  appId: "1:851176471532:web:f5043eb327d10abf769ec9",
  measurementId: "G-DHPKCR58F8"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

function App() {
  const [appV, setAppV] = React.useState("");
  const {appUser} = useUser();
  const {t, i18n} = useTranslation();
  const messaging = getMessaging(app);

  useEffect(() => {
    async function removeTokenFromFirestore() {
      const db = getFirestore();
      const docRef = doc(db, "admin-tokens", appUser.id);
      await setDoc(docRef, {
        token: "",
        updatedAt: serverTimestamp(),
      }, {merge: true});
    }
    async function uploadTokenToFirestore(token) {
      const db = getFirestore();
      const docRef = doc(db, "admin-tokens", appUser.id);
      await setDoc(docRef, {
        token: token,
        updatedAt: serverTimestamp(),
      }, {merge: true});
    }
    const requestPermission = async () => {
      try {
        // Request permission to send notifications
        await Notification.requestPermission();
        if (Notification.permission === "granted") {
          // Get the messaging token
          const token = await getToken(messaging);
          uploadTokenToFirestore(token);
          console.log("Token: ", token);
        }
      } catch (error) {
        console.error("Permission denied", error);
      }
    };
    if (appUser?.role === "admin") {
      requestPermission();
    }

    return () => {
      if (appUser?.role === "admin" && appUser?.id) {
        removeTokenFromFirestore();
      }
    }
  }, [appUser, messaging]);

  const [roleMessage, setRoleMessage] = React.useState("");
  useEffect(() => {
    if (appUser) {
      switch (appUser.role) {
        case "admin":
          setRoleMessage("Admin");
          break;
        case "user":
          setRoleMessage("Trader");
          break;
        default:
          setRoleMessage("No role assigned!");
      }
    } else {
      setRoleMessage("Not signed in!");
    }
  }, [appUser]);

  fetch('/manifest.json')
    .then(response => response.json())
    .then(data => setAppV(data.version));

  function signOut() {
    const auth = getAuth();
    auth.signOut();
  }

  return (
    <SnackbarProvider maxSnack={3}>
      < div className='app-container'>
        <TeamsAppBar />
        {/* {appUser?.role === "admin" && <AdminPage />}
        {appUser?.role === "user" && < TraderPage />} */}
        {(!appUser || (appUser?.role === "none")) && <Login />}
        <div className="container">
          <div className="left" />
          <div className="centered" />
          <div className="right-foot">
            Version {appV}
          </div>
        </div>
      </div>
    </SnackbarProvider>
  );
}

export default App;